import ReactDOM from 'react-dom/client'
import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import Dating from './posts/Dating'
import Admin from './pages/Admin'
import Population from './posts/Population'
import { ErrorPage } from './components/utils'
import Housing from './posts/Housing'
import CaliHistory from './posts/CaliHistory'
import ChatRoom from './pages/ChatRoom'
import FileUploader from './pages/FileUploader'
import { CameraRoom, RTCRoom, VideoRoom } from './pages/VideoRoom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Outlet />} errorElement={<ErrorPage />}>
        <Route path="admin" element={<Admin />} />
        <Route path="chatroom" element={<ChatRoom />} />
        <Route path="videoroom" element={<RTCRoom />} />
        <Route path="file_upload" element={<FileUploader />} />
        <Route path="dating" element={<Dating post_id={1} />} />
        <Route path="population" element={<Population post_id={2} />} />
        <Route path="housing" element={<Housing post_id={3} />} />
        <Route path="cali_history" element={<CaliHistory post_id={4} />} />
        <Route index element={<App />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
