import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import logisticService from '../services/logistics'
import { io } from 'socket.io-client'

const cameraFeedURL = process.env['REACT_APP_API_BASE_URL'] + '/monitor/video'
const videoFeedURL = process.env['REACT_APP_API_BASE_URL'] + '/files/downloads/output.mp4'
const videoPartialFeedURL = process.env['REACT_APP_API_BASE_URL'] + '/files/stream/output.mp4'

const sockio_url = process.env['REACT_APP_SOCKET_IO_URL']


const CameraRoom = () => {

  const [frame, setFrame] = useState('')
  const handleOnClose = () => {
    logisticService.closeCamera()
      .then(response => {
        console.log(`successfully closed ${response.data}`)
        setFrame('')
      })
  }

  const handleOpenCamera = () => {
    console.log(`send open request`)
    logisticService.openCamera()
      .then(response => {
        console.log(`successfully opened ${response.data}`)
        setFrame(cameraFeedURL)
      })
      .catch(e => {
        console.log(`already opened ${e}`)
        setFrame(cameraFeedURL)
      })
  }

  return (
    <div>
      <p>Welcome to camera room</p>
      {/*<video id="gum-local" autoPlay playsInline></video>*/}
      <img src={frame} width="100%" alt={'nice'} />
      <button onClick={handleOpenCamera}>Open</button>
      <button onClick={handleOnClose}>Close</button>
    </div>
  )
}

const VideoRoom = () => {

  const [frame, setFrame] = useState('')
  const handleOnClose = () => {
    logisticService.closeCamera()
      .then(response => {
        console.log(`successfully closed ${response.data}`)
        setFrame('')
      })
  }

  const requestPartialVideo = () => {
    console.log(`send partial request`)
    logisticService.streamVideoFile('output.mp4', {
      'Range': 'bytes=0-1'
    })
      .then(response => {
        console.log(`successfully opened ${response.data}`)
        setFrame(cameraFeedURL)
      })
      .catch(e => {
        console.log(`already opened ${e}`)
        setFrame(cameraFeedURL)
      })
  }

  return (
    <div>
      <p>Welcome to video room</p>
      <ReactPlayer url={videoPartialFeedURL} controls={true} />
    </div>
  )
}

const RTCRoom = () => {

  const [rtcSocket] = useState(io(sockio_url))
  const remoteVideoRef = useRef(null)
  const localStreamRef = useState(null)
  let pc // For RTCPeerConnection Object

  const sendData = (data) => {
    rtcSocket.emit('rtc_data', {
      core: data,
      room: 'room3'
    })
  }

  const startConnection = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          height: 350,
          width: 350
        }
      })
      .then((stream) => {
        console.log('Local Stream found')
        localStreamRef.current = stream
        rtcSocket.connect()
        rtcSocket.emit('join_rtc', { room: 'room3' })
      })
      .catch((error) => {
        console.error('Stream not found: ', error)
      })

  }

  const onIceCandidate = (event) => {
    if (event.candidate) {
      console.log('Sending ICE candidate')
      sendData({
        type: 'candidate',
        candidate: event.candidate
      })
    }
  }

  const onTrack = (event) => {
    console.log(`Adding remote track ${event.streams[0].getVideoTracks()[0].getSettings().frameRate}`)
    remoteVideoRef.current.srcObject = event.streams[0]
  }

  const onConnectStateChange = (pc, event) => {
    console.log('connection state changed')
    switch (pc.connectionState) {
      case 'new':
      case 'connecting':
        console.log(`Connecting ${pc.connectionState}`)
        break
      case 'connected':
        console.log(`Online ${pc.connectionState}`)
        break
      default:
        console.log(`weird state ${pc.connectionState}`)
        break
    }
  }

  const createPeerConnection = () => {
    try {
      pc = new RTCPeerConnection({})
      pc.onicecandidate = onIceCandidate
      pc.ontrack = onTrack
      pc.onconnectionstatechange = onConnectStateChange
      const localStream = localStreamRef.current
      for (const track of localStream.getTracks()) {
        pc.addTrack(track, localStream)
      }
      console.log('PeerConnection created with tracks added')
    } catch (error) {
      console.error('PeerConnection failed: ', error)
    }
  }

  const setAndSendLocalDescription = (sessionDescription) => {
    pc.setLocalDescription(sessionDescription)
    console.log('Local description set')
    sendData(sessionDescription)
  }

  const sendOffer = () => {
    console.log('Sending offer')
    pc.createOffer().then(setAndSendLocalDescription, (error) => {
      console.error('Send offer failed: ', error)
    })
  }

  const sendAnswer = () => {
    console.log('Sending answer')
    pc.createAnswer().then(setAndSendLocalDescription, (error) => {
      console.error('Send answer failed: ', error)
    })
  }

  const signalingDataHandler = (data) => {
    if (data.type === 'offer') {
      console.log(`handling offer ${JSON.stringify(data)}`)
      createPeerConnection()
      pc.setRemoteDescription(new RTCSessionDescription(data))
      sendAnswer()
    } else if (data.type === 'answer') {
      console.log(`handling answer ${JSON.stringify(data)}`)
      pc.setRemoteDescription(new RTCSessionDescription(data))
    } else if (data.type === 'candidate') {
      console.log(`handling candidate ${JSON.stringify(data.candidate)}`)
      pc.addIceCandidate(new RTCIceCandidate(data.candidate))
    } else {
      console.log('Unknown Data')
    }
  }

  useEffect(() => {
    rtcSocket.on('ready', () => {
      console.log('Ready to Connect!')
      createPeerConnection()
      sendOffer()
    })

    rtcSocket.on('connect', (socket) => {
      console.log('server connected')
    })

    rtcSocket.on('rtc_data', (data) => {
      signalingDataHandler(data['core'])
    })
    startConnection()
    return function cleanup() {
      pc?.close()
    }
  }, [])

  return (
    <div>
      <p>Welcome to RTC room</p>
      <video autoPlay muted playsInline ref={remoteVideoRef} />
    </div>
  )
}


export { CameraRoom, VideoRoom, RTCRoom }